import '../App.css'
function About1() {

    return (

        <div className="about1bg">

            <div className="formbglogo"></div>
            <div className="about1cen">
                <div className="parent">
                    <div className='gird1'></div>
                    <div className='gird2'></div>
                    <div className='gird3'></div>
                    <div className='div4'></div>
                </div>
            </div>
            <div className="about1right">
                <h1 className='h1about1 ibrtitle1'>Who We Are</h1>
                <h2 className='h2about1 subtitle'>Welcome to MedAsk</h2>
                <p className='pabout1'>
                Welcome to <b>MedAsk</b>, your trusted healthcare partner dedicated to delivering high-quality medical services. We enhance patient care through our diverse offerings, including pharmacy services, medical tourism, and soon-to-launch home health and diagnostic services
                </p>
                
            </div>
        </div>

    );
}

export default About1;
