import React from 'react'
import '../App.css'
function Head2(){

return (
    <div>
    <h1 className="h1ofhead2">Medical Expertise Beyond Borders</h1>
    <h2 className='h2ofhead2'>MedAsk Medical Tourism</h2>
    </div>
);
}
export default Head2