import React, { useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';

interface Article {
	id: number;
	date: string;
	title: string;
	description: string;
	link: string;
	image: string;
	dateColor: string;
}

const DetailedNews: React.FC = () => {
	const [articles, setArticles] = useState<Article[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

	useEffect(() => {
		const fetchArticles = async () => {
			try {
				const response = await axios.get<Article[]>('https://medask.com.pk/api.php/api/articles');
				setArticles(response.data);
				if (response.data.length > 0) setSelectedArticle(response.data[0]); // Select the first article by default
			} catch (err) {
				console.error('Error fetching articles:', err);
				setError('Error fetching articles');
			} finally {
				setLoading(false);
			}
		};

		fetchArticles();
	}, []);

	if (loading) {
		return <div>Loading...</div>; // Loading state
	}

	if (error) {
		return <div>{error}</div>; // Error state
	}

	return (
		<section className="news-section" style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
			{/* Left: Tabs */}
			<div className="news-tabs" style={{ flex:'2 1 0%', borderRight: '1px solid #ccc', paddingRight: '10px' }}>
				{articles.map((article) => (
					<div
						key={article.id}
						className="news-tab"
						style={{
							padding: '10px',
							cursor: 'pointer',
                            display:'flex',
                            marginTop:'30px',
                            height:'100px',
							backgroundColor: selectedArticle?.id === article.id ? '#f0f0f0' : 'transparent',
						}}
						onClick={() => setSelectedArticle(article)}
					>
                        <div className="news-image">
								<img src={article.image} alt={article.title} style={{width: '100px', height: '30%', justifyContent:'start', display:'flex' }} />
							</div>
						<h4 className="news-title">{article.title}</h4>
					</div>
				))}
			</div>

			{/* Right: Detailed Content */}
			<div className="news-details" style={{ flex: 3 }}>
				{selectedArticle ? (
					<div>
						<div className="news-imageDetailed">
							<img src={selectedArticle.image} alt={selectedArticle.title} style={{ width: '50%', height: 'auto' }} />
						</div>
						<div style={{marginBottom:'-20px'}}>
                        <div className="news-datedetailed" >{selectedArticle.date}</div>
						</div>
						<h2 className="news-title">{selectedArticle.title}</h2>
                        <div style={{ justifyContent:'center', display:'flex'}}>
						<p className="news-description" style={{width:'50%', textAlign:'start'}}>It is a long established fact that a reader will be distracted by the readable content Lorem Ipsum is that distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. <br /> {selectedArticle.description}</p>
						</div>
					</div>
				) : (
					<p>Select an article to view details</p>
				)}
			</div>
		</section>
	);
};

export default DetailedNews;
