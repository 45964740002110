import '../App.css';

function VideoText({ text }: { text: string }) {
	return (
		<div className="divcen">
			<p className="vidtext">
				{text}
			</p>
		</div>
	);
}

export default VideoText;
