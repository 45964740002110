import React from 'react';
import { Helmet as HelmetType } from 'react-helmet';
import Bnews from '../components/Bnews';
import Section4 from '../components/Section4';
import Head from '../components/Head';
import Div1 from '../components/div1';
import Section8 from '../components/Section8';
import Labtab from '../components/Labtab';
import Form from '../components/Form';
import NewsSection from '../components/NewsSection';
import VideoCarousel from '../components/videocarousel';
import Videohead from '../components/Videohead';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Section1 from '../components/Section1';
import '../App.css';
import '../components/ibrdiv1.css';
import Section3 from '../components/Section3';

const Helmet = HelmetType as React.ComponentType<any>;


const HomePage = () => {
  
  return (
    <div className="HomePage">
      {/* Add Helmet for meta tags */}
      <Helmet>
        <title>MedAsk is the leading pharmaceutical & medical tourism provider</title>
        <meta
          name="description"
          content="MedAsk runs pharmacies at key hospitals around the country and helps overseas patients to avail best treatment options through concierge medical tourism services in Pakistan."
        />
        <meta
          name="keywords"
          content="MedAsk, Medask, med ask, pharmacy, pharma, pharmaceutical, pharmaceutical services, medical tourism, health tourism, medical tourism in Pakistan, health tourism in Pakistan"
        />
        <meta name="author" content="MedAsk Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Page Content */}
      {/* <Header /> */}
      <Section1 />

      <div className="Bgofallwebvector">
        <Bnews />
        <Head />
        <Div1 />
        <Section3 />
        {/* <div className="div1bgcolor">
        </div> */}
      </div>

      {/* <Section4 /> */}
      <div className="section5bgimg">
        <Videohead />
        <VideoCarousel />
      </div>
      <Labtab />

      {/* <Labtab /> */}
      <NewsSection />
      <Form />
      {/* <Footer /> */}
    </div>
  );
};

export default HomePage;