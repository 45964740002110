import React from 'react';
import { Helmet as HelmetType } from 'react-helmet';
import FormContact from '../components/FormContact';
import Con1 from '../components/Contact1';
import { Container } from '@mui/material';
import '../App.css';

// Type cast Helmet to avoid TypeScript issues
const Helmet = HelmetType as React.ComponentType<any>;

function ContactUsPage() {
  return (
    <>
      {/* Add Helmet for meta tags */}
      <Helmet>
        <title>Contact MedAsk for Pharmaceutical & Medical Tourism Services</title>
        <meta
          name="description"
          content="Reach out to MedAsk for queries related to pharmaceuticals, medical tourism, and other services. Get the assistance you need from our professional team."
        />
        <meta
          name="keywords"
          content="MedAsk Contact, Contact MedAsk, Pharmaceutical Contact, Medical Tourism Contact, MedAsk assistance, MedAsk email, MedAsk phone"
        />
        <meta name="author" content="MedAsk Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Page Content */}
      <Con1 />
      <FormContact />
    </>
  );
}

export default ContactUsPage;