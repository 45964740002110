import '../App.css'
function Head(){

return (
    <div className='headofsection3'>
    <h1 className="h1ofhead1">Our Pharmacies</h1>
    <h2 className='h2ofhead1'>About MedAsk Pharmacies</h2>
    </div>
);
}
export default Head