import React from 'react';
import { Helmet as HelmetType } from 'react-helmet';
import Form from '../components/Form';
import { Container } from '@mui/material';
import Phar1 from '../components/Phar1';
import Phar2 from '../components/Phar2';
import Phar3 from '../components/Phar3';
import Phar4 from '../components/phar4';
import Phar5 from '../components/phar5';
import Phar6 from '../components/phar6';
import '../App.css';

// Type cast Helmet to avoid TypeScript errors
const Helmet = HelmetType as React.ComponentType<any>;

function MedicalTourismPage() {
  return (
    <>
      {/* Add Helmet for meta tags */}
      <Helmet>
        <title>MedAsk Tours providing Medical Tourism packages</title>
        <meta
          name="description"
          content="MedAsk Tours provides medical tourism or health tourism options for foreign nationals and overseas Pakistanis at affordable costs at top-notch hospitals in Pakistan."
        />
        <meta
          name="keywords"
          content="MedAsk Tours, Medask Tours, med ask Tours, medical tourism, health tourism, Medical tourism Pakistan, health tourism in Pakistan, healthcare for overseas Pakistanis"
        />
        <meta name="author" content="MedAsk Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Page Content */}
      <Phar1 />
      <Phar2 />
      <Phar3 />
      <Phar4 />
      <Phar5 />
      <Phar6 />
      <Form />
    </>
  );
}

export default MedicalTourismPage;