import React from 'react'
import '../App.css'
// import './kmlVideohead.css'
function Videohead(){

return (
    <div style={{marginTop:'20px', }}>
    <h1 className="h1ofvidhead1">Videos</h1>
    <h2 className='h2ofvidhead2'>MedAsk activities</h2>
    </div>
);
}
export default Videohead;