import React, { useEffect } from 'react';
import LatestNews from './LatestNews';
import { useNavigate } from 'react-router-dom';
import VideoCarousel from './Videos';
import ART from './article';

const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate('/login'); // Redirect to login if no user data found
        }
    }, [navigate]);
    
    const handleLogout = () => {
        localStorage.removeItem('user'); // Clear user data from localStorage
        navigate('/login'); // Redirect to login page
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Dashboard</h1>
            
            {/* Logout Button */}
            <button onClick={handleLogout} style={styles.logoutButton}>Logout</button>

            {/* Latest News Section */}
            <div style={styles.section}>
                <LatestNews />
            </div>
            
            {/* Videos Section */}
            <div style={styles.section}>
                <VideoCarousel />
            </div>
       
            <div style={styles.section}>
                <ART />
            </div>
        </div>
    );
};

// Internal styles for Dashboard
const styles = {
    container: {
        width: '100%',
        fontFamily: 'Arial, sans-serif',
        padding: '50px 20px',
        boxSizing: 'border-box' as 'border-box',
    },
    title: {
        textAlign: 'center' as 'center',
        fontSize: '2rem',
        marginBottom: '20px',
        color: '#333',
    },
    section: {
        marginBottom: '40px',
        marginTop: '10px',
    },
    logoutButton: {
        backgroundColor: 'blue',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
        display: 'block',
        marginLeft: 'auto',
        right: 0,
        marginTop: '50px',
    },
};

export default Dashboard;
