import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Helper function to set item with expiry in localStorage
const setWithExpiry = (key: string, value: any, expiryInMinutes: number) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + expiryInMinutes * 60 * 1000, // Calculate expiry time
    };
    localStorage.setItem(key, JSON.stringify(item));
};

// Function to get item from localStorage with expiry check
const getWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the expiry time with the current time
    if (now.getTime() > item.expiry) {
        // If expired, remove the item from localStorage and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Check if user is already logged in (check if expired)
    useEffect(() => {
        const user = getWithExpiry('user');
        if (user) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleLogin = async (event: React.FormEvent) => {
      event.preventDefault();
      setError(null); // Reset error message
  
      try {
          const formData = new URLSearchParams();
          formData.append('username', username);
          formData.append('password', password);
  
          const response = await axios.post(
              'https://medask.com.pk/api.php/api/login',
              formData,
              { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
          );
  
          if (response.status === 200 && response.data.user) {
              // Save user data in localStorage
              localStorage.setItem('user', JSON.stringify(response.data.user));
  
              console.log('Login successful:', response.data);
              navigate('/dashboard');
          } else {
              console.error('Unexpected response:', response.data);
              setError(response.data.error || 'Invalid username or password.');
          }
      } catch (err) {
          if (axios.isAxiosError(err)) {
              console.error('Axios Error:', err.response?.data || err.message);
              setError(err.response?.data?.error || 'An error occurred during login.');
          } else {
              console.error('Unexpected Error:', err);
              setError('An unexpected error occurred.');
          }
      }
  };

    return (
        <div className="login-container" style={{marginTop: '200px'}} >
            <h2>Login</h2>
            {error && <div className="error">{error}</div>}
            <form onSubmit={handleLogin}>
                <div className="form-group">
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="input-field"
                    />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input-field"
                    />
                </div>
                <button type="submit" className="login-button">Login</button>
            </form>
            <style>
                {`
                .login-container {
                    max-width: 400px;
                    margin: 100px auto;
                    padding: 40px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
                    background-color: #ffffff;
                    font-family: Arial, sans-serif;
                }
                h2 {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #333;
                }
                .error {
                    color: red;
                    margin-bottom: 10px;
                    text-align: center;
                    font-weight: bold;
                }
                .form-group {
                    margin-bottom: 20px;
                }
                label {
                    margin-bottom: 5px;
                    font-weight: bold;
                    color: #555;
                }
                .input-field {
                    margin-bottom: 10px;
                    padding: 12px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 16px;
                    transition: border-color 0.3s;
                    width: 100%;
                }
                .input-field:focus {
                    border-color: #4caf50;
                    outline: none;
                }
                .login-button {
                    padding: 12px;
                    background-color: #4caf50;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.3s;
                    width: 100%;
                }
                .login-button:hover {
                    background-color: #45a049;
                }
                `}
            </style>
        </div>
    );
};

export default LoginPage;
