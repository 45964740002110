import React from 'react';
import { Helmet as HelmetType } from 'react-helmet';
import Form from '../components/Form';
import Pharpage1 from '../components/Pharmaciespage';
import Phsection2 from '../components/Phsection2';
import Phsection3 from '../components/Phsection3';
import Phsection4 from '../components/Phsection4';
import Phsection5 from '../components/Phsection5';

// Type cast Helmet to avoid TypeScript errors
const Helmet = HelmetType as React.ComponentType<any>;

function PharmaciesPage() {
  return (
    <>
      {/* Add Helmet for meta tags */}
      <Helmet>
        <title>MedAsk is the leading pharmacy chain in Pakistan</title>
        <meta
          name="description"
          content="MedAsk runs pharmacies at key hospitals around the country and provides high-class medicine to its customers."
        />
        <meta
          name="keywords"
          content="MedAsk, Medask, med ask, pharmacy, pharma, pharmaceutical, pharmaceutical services, pharmacies, leading pharmacies, pharmaceutical company in Pakistan"
        />
        <meta name="author" content="MedAsk Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Page Content */}
      <Pharpage1 />
      <Phsection2 />
      <Phsection3 />
      <Phsection4 />
      <Phsection5 />
      <Form />
    </>
  );
}

export default PharmaciesPage;