import '../App.css'
function Gruop(){


return(
<div className="png-logoseacc">
  <svg width="100%" height="100%" viewBox="0 0 13 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.25937 0.726562H0.75V75.9669H3.25937V0.726562Z" fill="#00AA14"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4705 37.937L3.2594 43.3626V32.5029L12.4705 37.937Z" fill="#00AA14"/>
  </svg>
</div>
);
}
export default Gruop