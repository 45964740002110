import '../App.css';
import greenhr from '../images/greenhr.png';

function SecOneNewsPage() {
    return (
        <>
            <div className="head">
                <div className="head1bg1">
                    <h1 className="aboutussec1h1 ibrtitle1">MedAsk</h1>
                    <h1 className="aboutussec1h2 subtitle">Detailed News</h1>
                    <div className='hr-parent'>
                        <hr className="hr-line1" />
                        <img src={greenhr} className="greenhr1" alt="Green Horizontal Rule" />
                    </div>
                    <p className='aboutussec1p1'>
                    Your Gateway to Exceptional Medical Tourism and Global Healthcare Solutions
                    </p>
                </div>
                <div className="head1bg2"></div>
                <div className="head1bg3"></div>
            </div>
        </>
    );
}

export default SecOneNewsPage;
